import request from '@/service/request'

// 根据原型id获取详情
export async function getDeclaredInfo(prototypeIdList) {
  // eslint-disable-next-line no-undef
  let declaredInfo = (await awaitResolve(getDeclaredInfoByProId(prototypeIdList)))?.detail?.[0]
  if (!declaredInfo) {
    // Message.warning('该产品无报关信息，请先完善产品信息')
    return
  }

  return declaredInfo
}
export function getDeclaredInfoByProId(prototypeIdList) {
  prototypeIdList = Array.isArray(prototypeIdList) ? prototypeIdList : [prototypeIdList]
  return request({
    url: '/externaladmin/productService/productPrototypeDeclarationInfo/prototypeList',
    method: 'post',
    data: {
      prototypeIdList
    }
  })
}

// 更新一条
export function updateDeclarationInfo(data) {
  return request({
    url: '/externaladmin/productService/productPrototypeDeclarationInfo/update',
    method: 'post',
    data
  })
}