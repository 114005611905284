<template>
  <div class="detailOrEditComponent">
    <BaseDialog
      class="orderDetailComponent"
      :dialogVisible.sync="visible"
      height="60vh"
      width="700px"
      top="50px"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div class="drawerBody" v-loading="loading">
        <div class="item" v-if="!isTemuOrder">
          <div class="bodyTitle">
            <span>物流信息</span>
          </div>
          <BaseForm
            ref="expressForm"
            label-position="left"
            label-suffix=" "
            size="mini"
            customClass="customClass"
            :gutter="10"
            :topPosition="true"
            :cols="fedexField"
            :form="form"
            :isDisable="isDisable"
          >
            <template #expressCompanyIdSlot="{ scoped: { prop, placeholder, disabled } }">
              <!-- <el-select size="mini" style="width: 100%" clearable v-model="form[prop]" :disabled="disabled" :placeholder="placeholder">
                <el-option
                  :label="companyName"
                  :value="id"
                  :key="id"
                  v-for="{ id, companyName } in fedexData"
                ></el-option>
              </el-select> -->
              <el-input style="width: 248px" :value="expressName(form)" disabled></el-input>
            </template>
          </BaseForm>
        </div>

        <div class="item" v-if="!isTemuOrder">
          <div class="bodyTitle">
            <span>报关信息</span>
          </div>
          <baseTable :data="declarationData" :option="declarationOption">
            <template #menu="{ row }">
              <editDeclaration
                v-if="!isDisable"
                :value="row"
                :data="data"
                :showFormAppend="true"
                :showOriginalDeclaredInfo="!row.isCombinedProduct"
                @submit="hasChanged = true"
              ></editDeclaration>
            </template>
          </baseTable>
        </div>

        <div class="item">
          <div class="bodyTitle">收货信息</div>
          <BaseForm
            ref="consigneeForm"
            label-position="left"
            label-suffix=" "
            size="mini"
            customClass="customClass"
            label-width="118px"
            :gutter="10"
            :topPosition="true"
            :cols="consigneeField"
            :form="form"
            :isDisable="isDisable"
          >
          </BaseForm>
        </div>

        <div class="item">
          <div class="bodyTitle">产品信息</div>
          <div class="mb10">
            <span>订单号：{{ form.orderCode }}</span>
            <span class="ml20">导入时间：{{ form.orderTime }}</span>
          </div>
          <template v-if="!isProTable">
            <BaseForm
              v-for="(proFrom, index) in proData"
              ref="proForm"
              label-position="left"
              label-suffix=" "
              size="mini"
              customClass="customClass"
              :key="index"
              :gutter="10"
              :topPosition="true"
              :cols="productField"
              :form="proFrom"
            >
              <template #productExternalSkuCodeSlot="{ scoped: { prop, placeholder } }">
                <sku-select
                  v-model="proFrom[prop]"
                  :disabled="!!proFrom.id"
                  :placeholder="placeholder"
                  @realValue="proFrom[`$${prop}`] = $event"
                ></sku-select>
              </template>
              <template #doneSlot>
                <i @click="shakingDel(index)" class="el-icon-delete" v-if="showDel"></i>
              </template>
            </BaseForm>
            <color-text-btn v-if="!isDetail" icon="el-icon-plus" type="primary" @click="addProduct">
              新增产品
            </color-text-btn>

            <color-text-btn v-else icon="el-icon-plus" type="info"> 新增产品 </color-text-btn>
          </template>
          <template v-else>
            <CommonTable class="common-table" height="auto" :selection="false" :cols="cols" :infoData="proData">
              <template #productCnNameSlotName="{ scoped: { productShowCover, productCnName } }">
                <div class="cell-img-text">
                  <baseImage :src="productShowCover" size="50"></baseImage>
                  <div>{{ productCnName }}</div>
                </div>
              </template>
              <template #productStructNameSlotName="{ scoped: { productStructName } }">
                {{ productStructName == 0 ? '--' : productStructName }}
              </template>
              <template #productSizeNameSlotName="{ scoped: { productSizeName } }">
                {{ productSizeName == 0 ? '--' : productSizeName }}
              </template>
            </CommonTable>
          </template>
        </div>
      </div>

      <template #footer>
        <el-button @click="close"> 取消 </el-button>
        <el-button type="primary" :loading="loading" @click="onsubmit" v-if="!isDetail">保存</el-button>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import colorTextBtn from '@/components/base/colorTextBtn.vue'
import skuSelect from '../skuSelect'
import editDeclaration from '@/views/order/module/editDeclaration'
import cloneDeep from 'lodash/cloneDeep'
import { parseTime } from '@/utils'
import { getFedexField, consigneeField, productField, declarationOption } from './field'
import { proCols as cols } from './cols'
import { orderComplexUpdate as orderUpdate, orderItemUpdate } from '@/api/order'
import { mapGetters } from 'vuex'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { expressTrackMethod, PRODUCT_TYPE_COMBINATION, WAYBILL_TYPE_SYS } from '@/utils/constant'
import { validatenull } from '@/components/avue/utils/validate'

const PRO_ITEM = {
  productInternalSkuCode: null,
  productCount: 1
}
const IS_DETAIL = 0
const IS_EDIT = 1
export default {
  components: { colorTextBtn, skuSelect, editDeclaration },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    type: {
      type: [String, Number],
      default: IS_DETAIL
    },
    data: {
      type: Object,
      default: () => ({})
    },
    oProData: {
      type: Array,
      default: () => []
    },
    isProTable: {
      type: Boolean,
      default: false
    },
    productData: {
      type: Array,
      required: true
    },
    menuBtn: {
      type: Boolean,
      default: true
    },
    sup_this: {
      type: Object,
      default() {
        return {}
      }
    },
    orderStatus: {
      type: String
    },
    isTemuOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cols,
      productField,
      visible: false,
      becomeEdit: false,
      loading: false,
      form: {},
      fbaOrder: 2,
      order: 1,
      declarationData: [],
      declaredInfoOfProId: {}
    }
  },
  watch: {
    visible(newVal) {
      this.$emit('update:dialogVisible', newVal)
      if (newVal) {
        this.declaredInfoOfProId = {}
        this.form = Object.assign({}, { product: [] }, cloneDeep(this.data))
        this.oForm = Object.assign({}, { product: [] }, cloneDeep(this.data))
        consigneeField.forEach((item) => {
          const { prop } = item
          this.$set(this.form, prop, $GET(this.data, `externalOrderLogistics[${prop}]`, ''))
          this.$set(this.oForm, prop, $GET(this.data, `externalOrderLogistics[${prop}]`, ''))
        })
      } else {
        this.reset()
        if (this.hasChanged) {
          this.hasChanged = false
          this.sup_this?.init?.()
        }
      }
    },

    dialogVisible(newVal) {
      this.visible = newVal
    },

    data: {
      handler(n) {
        this.getDeclaredInfo(n?.productData)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['fedexData']),
    fedexField({ isDetail, data }) {
      let field = deepClone(getFedexField(this.orderStatus))

      let expressWaybillCode = field.find((item) => item.name === 'expressWaybillCode')
      expressWaybillCode.prop = expressTrackMethod[data.expressTrackMethod]

      if (data.waybillType === WAYBILL_TYPE_SYS) {
        field.forEach((item) => (item.disabled = true))
      } else if (!isDetail) {
        field.forEach((item) => {
          item.rule = [
            {
              validator: (rule, value, callback) => {
                let { expressCompanyId, [expressWaybillCode.prop]: expressCode } = this.form
                if ((!expressCompanyId && expressCode) || (expressCompanyId && !expressCode)) {
                  callback('物流方式和运单号应同时存在或不存在')
                } else {
                  this.$refs?.expressForm.clearValidate()
                  callback()
                }
              }
            }
          ]
        })
      }
      return field
    },
    declarationOption() {
      return {
        ...declarationOption,
        menu: !this.isDisable
      }
    },
    consigneeField({ isDetail }) {
      // import导入的consigneeField会被缓存，因此每次调用的都是同一个值
      let field = deepClone(consigneeField)
      if (isDetail) {
        field.forEach((item) => delete item.rule)
      } else {
        field[2].rule = [
          {
            validator: (rule, value, callback) => {
              if (!value && !this.form.consigneeContactMobilePhone) {
                return callback('收货人电话和收货人手机号码其中一个必须有值')
              }
              callback()
            }
          }
        ]
        field[3].rule = [
          {
            validator: (rule, value, callback) => {
              if (!value && !this.form.consigneeContactPhone) {
                return callback('收货人电话和收货人手机号码其中一个必须有值')
              }
              callback()
            }
          }
        ]
      }
      return field
    },
    proData() {
      try {
        return this.form.productData || []
      } catch (err) {
        return []
      }
    },
    isDisable() {
      if (this.type == IS_DETAIL) {
        return true
      } else {
        return false
      }
    },
    isDetail() {
      return this.type == IS_DETAIL
    },
    hasMenuBtn() {
      if (this.becomeEdit) return this.becomeEdit
      return this.menuBtn
    },
    showDel() {
      return this.proData.length > 1 && !this.isDetail
    }
  },
  methods: {
    parseTime,
    expressName(scoped) {
      if (!scoped.freightPaymentTime && scoped.orderType == this.fbaOrder) return ''
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.expressShippingMethodCnName || scoped.expressCompanyName
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    },
    reset() {
      this.becomeEdit = false
      this.form = {}
    },
    toEdit() {
      this.becomeEdit = true
    },
    close() {
      this.visible = false
    },
    shakingDel(index) {
      this.form.productData.splice(index, 1)
    },
    async onsubmit() {
      this.loading = true
      const valid = await this.validate()
      const orderValid = await this.orderValidate()
      if (!valid || !orderValid) {
        this.loading = false
        return
      }
      try {
        let { form, oForm } = this
        form = getDiffData(form, oForm)
        const res = []
        if (form) {
          form.id = oForm.id
          res.push(await orderUpdate(form))
        }
        res.push(await this.doFn())
        const successBool = res.every(({ code }) => $SUC({ code }))
        if (successBool) {
          if (this.sup_this) {
            this.sup_this.init()
          }

          this.$message.success('操作成功')
          this.close()
        }
      } catch (err) {}
      this.loading = false
    },
    doFn() {
      let { proData, oProData } = this

      const addList = proData
        .filter((item) => {
          item.specificProductId = item.$productExternalSkuCode?.specificProductId
          return !item.id
        })
        .map(({ specificProductId, productCount, productExternalSkuCode }) => {
          return {
            productCount,
            productExternalSkuCode,
            specificProductId
          }
        })

      let oIdList = oProData.map((item) => item.id)
      let nIdList = proData.map((item) => item.id)
      const delList = oIdList.filter((id) => !nIdList.includes(id))

      let countList = proData
        .filter((item) => {
          return item.id
        })
        .map((item) => {
          return {
            id: item.id,
            productCount: item.productCount
          }
        })

      const data = {
        orderId: this.data.id,
        countUpdateList: countList,
        delItemIdList: delList,
        relCreateItemList: addList
      }
      return orderItemUpdate(data)
    },
    //格式化数据
    formatRow(row) {
      console.log('rowdsadsddsa', row)
      return {
        id: row.id,
        productCount: row.productCount,
        productExternalSkuCode: row.productExternalSkuCode,
        specificProductId: row.specificProductId
      }
    },

    addProduct() {
      this.form.productData.push({
        ...PRO_ITEM
      })
    },

    to() {
      const time = this.time
      if (time && time.length) {
        const timeProp = this.timeProp
        this.query[`${timeProp}_before`] = parseCharacterTime(new Date(`${this.time[0]} 00:00:00`))
        this.query[`${timeProp}_after`] = parseCharacterTime(new Date(`${this.time[1]} 23:59:59`))
      } else {
        delete this.query.startTime
        delete this.query.endTime
      }
      this.toQuery()
    },

    async orderValidate() {
      const { expressForm, consigneeForm } = this.$refs
      //console.log('form', form)
      const forms = [expressForm, consigneeForm]
      console.log('forms', forms)
      const successData = await Promise.all(
        forms.map(async (node) => {
          try {
            return await node.validate()
          } catch (err) {
            return false
          }
        })
      )

      return successData.every((bool) => bool)
    },
    async validate() {
      const { proForm } = this.$refs
      if (!proForm) return true
      const forms = Array.isArray(proForm) ? proForm : [proForm]
      const successData = await Promise.all(
        forms.map(async (node) => {
          try {
            return await node.validate()
          } catch (err) {
            return false
          }
        })
      )

      return successData.every((bool) => bool)
    },

    getDeclaredInfo(orderItemList) {
      if (validatenull(orderItemList)) return
      let tempArr = []
      orderItemList.forEach((item) => {
        tempArr.push(item)
      })
      console.log(JSON.parse(JSON.stringify(orderItemList)), tempArr)
      orderItemList = tempArr.filter(Boolean)

      // 相同原型的产品只显示一条，数量叠加
      // 未关联产品不显示
      let {
        declarationData,
        declaredInfoOfProId,
        data: { id: orderId }
      } = this
      declarationData.length = 0
      orderItemList.forEach((item) => {
        let { productCount, productCnName, productStructName } = item
        let declaredInfo = (item.declaredInfo && JSON.parse(item.declaredInfo)) || {}
        if (item.prototypeId) {
          let declaredInfoItem = declaredInfoOfProId[item.prototypeId]
          declaredInfoItem = Object.assign(declaredInfo, {
            productCount,
            orderId,
            orderItemId: [item.id],
            productId: item.productId,
            prototypeId: item.prototypeId,
            productPrototypeName: productCnName,
            productStructName: productStructName,
            isCombinedProduct: item.productType === PRODUCT_TYPE_COMBINATION,
            // 非报关信息相关字段
            $delList: [
              'isCombinedProduct',
              'orderId',
              'productCount',
              'orderItemId',
              'productId',
              'productPrototypeName',
              'prototypeId',
              'isSaveToUserDefined',
              // 放最后
              '$delList'
            ]
          })
          this.$set(declaredInfoOfProId, item.prototypeId, declaredInfoItem)

          // 报关信息只显示不同产品，相同产品则叠加数量
          declarationData.push(declaredInfoItem)
        }
      })
      for (const id in declaredInfoOfProId) {
        declarationData = declarationData.filter(({ prototypeId }) => prototypeId !== id)
        delete declaredInfoOfProId[id]
      }
      this.declarationData = declarationData
      // console.log(declarationData, declaredInfoOfProId, diffData)
    }
  }
}
</script>
<style lang="scss" scoped>
.orderDetailComponent {
  ::v-deep {
    .el-select > .el-input {
      width: 248px;
      height: 36px;
    }
    .el-input--mini .el-input__inner {
      width: 248px;
      height: 36px;
    }
    .el-form {
      .el-form-item__content {
        width: 248px;
        height: 36px;
      }
    }
    .customClass.customClass {
      padding: 0;
      .el-form {
        padding: 0;
        margin: 0;
      }
    }
  }
  .bodyTitle {
    border-left: 2px solid $color-primary;
    padding: 0 0 0 10px;
    margin-bottom: 10px;
    .edit {
      position: absolute;
      right: 25px;
      margin-top: -10px;
    }
  }
  .el-icon-delete {
    position: relative;
    top: 42px;
    font-size: 20px;
    color: $color-primary;
    cursor: pointer;
  }
}
</style>
