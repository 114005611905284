<template>
  <div class="inline-block">
    <el-button v-if="hasBtn" type="text" :disabled="value.$noDeclaredInfo" @click="to">
      <slot>修改</slot>
    </el-button>
    <el-dialog
      v-el-drag-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      title="编辑报关信息"
      width="600px"
      top="10vh"
      custom-class="edit-declaration-dialog dialog-header-middle dialog-footer-center"
      append-to-body
      @closed="onclosed"
    >
      <div class="form-wrapper" :class="{ 'form-append-wrapper': appendList.length }">
        <baseForm1 ref="form" v-model="form" :option="formOption"></baseForm1>
        <div v-if="appendList.length" class="form-append">
          <div v-for="(item, index) in appendList" :key="index" class="item text-cut">
            {{ item ? `(默认: ${item})` : '' }}
          </div>
        </div>
      </div>
      <!--<div v-if="footer" class="form-footer">
        <div v-if="hasWarningInfo" class="warning-info">
          <i class="el-icon-warning"></i>
          <span class="text">运单申请成功的订单修改报关信息将自动更新运单号。</span>
        </div>
        <div class="form-checkbox">
          <el-checkbox v-model="form.isSaveToUserDefined" :disabled="isAdmin">
            同步信息到<template>
              <router-link
                v-if="checkPermission(['business:product:declaration'])"
                to="/product/declaration"
                class="text-primary"
              >自定义报关信息</router-link>
              <span v-else class="text-primary">自定义报关信息</span>
            </template>，及其他需关联产品、未支付订单。
          </el-checkbox>
        </div>
      </div>-->
      <template slot="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <loadingBtn size="small" type="primary" @click="onsubmit">保存</loadingBtn>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { formOption } from './const'
import { PRODUCT_TYPE_ONE, WAIT_DISPATCH } from '@/utils/constant'
import { getDeclaredInfo, updateDeclarationInfo } from '@/api/product'
import { complexUpdateDeclare as orderUpdate } from '@/api/order'
import { cloneDeep } from 'lodash'
import { validatenull } from '@/components/avue/utils/validate'
import { dialogComponentsMixin } from '@/mixins'
import promise from '@/extend/mixins/dialog/promise'

export default {
  mixins: [dialogComponentsMixin, promise],
  props: {
    productType: {
      type: String|Number,
      default: PRODUCT_TYPE_ONE
    },
    hasBtn: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    },
    footer: {
      type: Boolean,
      default: true
    },
    isEditRequest: Boolean,
    showFormAppend: Boolean,
    showOriginalDeclaredInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.cachedDeclaredInfo = {}
    return {
      form: {},
      formOption: cloneDeep(formOption),
      appendList: []
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    hasWarningInfo({ data: { orderStatus } }) {
      return `${orderStatus}` === WAIT_DISPATCH
    }
  },
  watch: {
    dialogVisible: {
      handler(n) {
        if (n) {
          this.formVal()
        }
      },
      immediate: true
    }
  },
  methods: {
    to() {
      this.dialogVisible = true
      this.showOriginalDeclaredInfo && this.getOriginalDeclaredInfo(this.value.prototypeId)
    },
    async onsubmit() {
      if (!(await this.validate())) return
      if (typeof this.valueResolve === 'function') {
        if ((await this.valueResolve(this.form)) === false) return

        this.valueResolve = undefined
        this.dialogVisible = false
        return
      }

      let { value, form } = this

      if (this.isEditRequest) {
        let data = cloneDeep(value)
        let displayImagePath = data.$displayImagePath || data.displayImagePath
        delete data.$displayImagePath
        if (Array.isArray(displayImagePath)) data.displayImagePath = JSON.stringify(displayImagePath)
        await awaitResolve(updateDeclarationInfo(data), { isMsg: true })
      } else {
        let tempObj = {}
        let $delList = value.$delList
        for (const valueKey in value) {
          if (!$delList.includes(valueKey)) {
            tempObj[valueKey] = form[valueKey]
          }
        }
        let orderItemUpdateList = value.orderItemId.map(id => ({
          id,
          prototypeId: value.prototypeId,
          declaredInfo: JSON.stringify(tempObj),
          isSaveToUserDefined: value.isSaveToUserDefined
        }))
        const res = await awaitResolve(
          orderUpdate({
            id: value.orderId,
            externalOrderItemUpdateList: orderItemUpdateList
          })
        )
        console.log({ res, value, tempObj, orderItemUpdateList })
        if (!res) return
      }

      for (const prop in form) {
        this.$set(value, prop, form[prop])
      }

      this.$emit('submit')

      this.dialogVisible = false
    },
    async validate() {
      return await awaitFormResolve(this.$refs.form.validate())
    },
    formVal() {
      this.form = cloneDeep(this.value)
      this.form.isSaveToUserDefined = undefined
    },
    async getOriginalDeclaredInfo(prototypeId) {
      let declaredInfo = this.cachedDeclaredInfo[prototypeId]
      if (!declaredInfo) {
        this.cachedDeclaredInfo[prototypeId] = declaredInfo = prototypeId && await getDeclaredInfo(prototypeId) || {}
      }

      let { appendList } = this
      appendList.length = 0
      this.formOption.column.forEach((column) => {
        let { prop } = column

        if (this.showFormAppend) {
          appendList.push(prop === 'productPrototypeName' ? '' : (declaredInfo[prop] || '暂无'))
          column.defaultValue = undefined
        } else {
          if (prop !== 'productPrototypeName') {
            let defaultValue = declaredInfo[prop]
            this.$set(column, 'defaultValue', `${validatenull(defaultValue) ? '' : defaultValue}`)
          }
        }
      })
    },
    cancel() {
      this.dialogVisible = false
      this.$emit('cancel')
    },
    handleClose() {
      this.$refs.form.resetForm()
    },

    dialogOpen(data, callback) {
      this.dialogVisible = true
      this.formOption.column.forEach((column) => {
        if (['declaredMaterial', 'customsCode'].includes(column.prop)) {
          column.type = 'inputSelect'
          column.dicData = data.map(item => {
            let value = item[column.prop]
            return {
              label: `${ value }(${ item.chineseName })`,
              value
            }
          })
        }
      })
      if (typeof callback === 'function') return this.valueResolve = callback
      return this.createPromise()
    },
    onclosed() {
      this.formOption = cloneDeep(formOption)

      typeof this.valueReject === 'function' && this.valueReject()
    }
  }
}
</script>
<style lang="scss">
.edit-declaration-dialog {
  .el-dialog__body {
    padding-bottom: 0;
  }
  .avue-form__group {
    .el-col:first-child {
      .el-form-item {
        margin-bottom: 15px;
      }
    }
    .el-form-item__error {
      position: relative;
    }
  }

  .form-container {
    padding-right: 10%;
  }
  .form-append-wrapper {
    display: flex;
    .form-container {
      max-width: 58%;
      padding-right: 12px;
    }
  }
  .form-append {
    height: 100%;
    color: #9DA3AC;
    overflow: hidden;
    .item {
      height: 36px;
      line-height: 36px;
      margin-bottom: 22px;
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  .form-footer {
    margin: -5px 0 0 45px;
    color: $color-title;

    .el-checkbox__input+.el-checkbox__label {
      color: $color-title;
    }
  }
  .warning-info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    [class^=el-icon-] {
      font-size: 18px;
      color: $color-warning;
    }
    .text {
      margin-left: 8px;
    }
  }
  .form-checkbox {
    margin-bottom: 30px;
  }
}
</style>