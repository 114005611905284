var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailOrEditComponent"},[_c('BaseDialog',_vm._g(_vm._b({staticClass:"orderDetailComponent",attrs:{"dialogVisible":_vm.visible,"height":"60vh","width":"700px","top":"50px"},on:{"update:dialogVisible":function($event){_vm.visible=$event},"update:dialog-visible":function($event){_vm.visible=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{on:{"click":_vm.close}},[_vm._v(" 取消 ")]),(!_vm.isDetail)?_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.onsubmit}},[_vm._v("保存")]):_vm._e()]},proxy:true}])},'BaseDialog',_vm.$attrs,false),_vm.$listeners),[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"drawerBody"},[(!_vm.isTemuOrder)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"bodyTitle"},[_c('span',[_vm._v("物流信息")])]),_c('BaseForm',{ref:"expressForm",attrs:{"label-position":"left","label-suffix":" ","size":"mini","customClass":"customClass","gutter":10,"topPosition":true,"cols":_vm.fedexField,"form":_vm.form,"isDisable":_vm.isDisable},scopedSlots:_vm._u([{key:"expressCompanyIdSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
var disabled = ref_scoped.disabled;
return [_c('el-input',{staticStyle:{"width":"248px"},attrs:{"value":_vm.expressName(_vm.form),"disabled":""}})]}}],null,false,4159125350)})],1):_vm._e(),(!_vm.isTemuOrder)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"bodyTitle"},[_c('span',[_vm._v("报关信息")])]),_c('baseTable',{attrs:{"data":_vm.declarationData,"option":_vm.declarationOption},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var row = ref.row;
return [(!_vm.isDisable)?_c('editDeclaration',{attrs:{"value":row,"data":_vm.data,"showFormAppend":true,"showOriginalDeclaredInfo":!row.isCombinedProduct},on:{"submit":function($event){_vm.hasChanged = true}}}):_vm._e()]}}],null,false,1027971065)})],1):_vm._e(),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("收货信息")]),_c('BaseForm',{ref:"consigneeForm",attrs:{"label-position":"left","label-suffix":" ","size":"mini","customClass":"customClass","label-width":"118px","gutter":10,"topPosition":true,"cols":_vm.consigneeField,"form":_vm.form,"isDisable":_vm.isDisable}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("产品信息")]),_c('div',{staticClass:"mb10"},[_c('span',[_vm._v("订单号："+_vm._s(_vm.form.orderCode))]),_c('span',{staticClass:"ml20"},[_vm._v("导入时间："+_vm._s(_vm.form.orderTime))])]),(!_vm.isProTable)?[_vm._l((_vm.proData),function(proFrom,index){return _c('BaseForm',{key:index,ref:"proForm",refInFor:true,attrs:{"label-position":"left","label-suffix":" ","size":"mini","customClass":"customClass","gutter":10,"topPosition":true,"cols":_vm.productField,"form":proFrom},scopedSlots:_vm._u([{key:"productExternalSkuCodeSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('sku-select',{attrs:{"disabled":!!proFrom.id,"placeholder":placeholder},on:{"realValue":function($event){proFrom[("$" + prop)] = $event}},model:{value:(proFrom[prop]),callback:function ($$v) {_vm.$set(proFrom, prop, $$v)},expression:"proFrom[prop]"}})]}},{key:"doneSlot",fn:function(){return [(_vm.showDel)?_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.shakingDel(index)}}}):_vm._e()]},proxy:true}],null,true)})}),(!_vm.isDetail)?_c('color-text-btn',{attrs:{"icon":"el-icon-plus","type":"primary"},on:{"click":_vm.addProduct}},[_vm._v(" 新增产品 ")]):_c('color-text-btn',{attrs:{"icon":"el-icon-plus","type":"info"}},[_vm._v(" 新增产品 ")])]:[_c('CommonTable',{staticClass:"common-table",attrs:{"height":"auto","selection":false,"cols":_vm.cols,"infoData":_vm.proData},scopedSlots:_vm._u([{key:"productCnNameSlotName",fn:function(ref){
var ref_scoped = ref.scoped;
var productShowCover = ref_scoped.productShowCover;
var productCnName = ref_scoped.productCnName;
return [_c('div',{staticClass:"cell-img-text"},[_c('baseImage',{attrs:{"src":productShowCover,"size":"50"}}),_c('div',[_vm._v(_vm._s(productCnName))])],1)]}},{key:"productStructNameSlotName",fn:function(ref){
var productStructName = ref.scoped.productStructName;
return [_vm._v(" "+_vm._s(productStructName == 0 ? '--' : productStructName)+" ")]}},{key:"productSizeNameSlotName",fn:function(ref){
var productSizeName = ref.scoped.productSizeName;
return [_vm._v(" "+_vm._s(productSizeName == 0 ? '--' : productSizeName)+" ")]}}])})]],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }